import React from "react";

import BASLogo from "../basis-share-logo.svg";
import BACLogo from "../basis-cash-logo.svg";
import DAILogo from "../DAI.png";

const horizontalMenuConfig = [
  {
    id: "vaults",
    title: "Vaults",
    type: "item",
    icon: <img src={BASLogo} width={20} alt="BAS Logo" />,
    navLink: "/",
  },
];

export default horizontalMenuConfig;
