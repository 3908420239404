import React, { useEffect, useState } from "react";

import Address from "./Address";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  authereum,
  frame,
  injected,
  ledger,
  magic,
  network,
  portis,
  torus,
  trezor,
  walletconnect,
} from "../connectors";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { useEagerConnect, useInactiveListener } from "../web3hooks";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import Row from "reactstrap/lib/Row";

enum ConnectorNames {
  Injected = "Metamask/Web3",
  Network = "Network",
  WalletConnect = "WalletConnect",
  WalletLink = "WalletLink",
  Ledger = "Ledger",
  Trezor = "Trezor",
  Frame = "Frame",
  Authereum = "Authereum",
  Fortmatic = "Fortmatic",
  Magic = "Magic",
  Portis = "Portis",
  Torus = "Torus",
}
function getErrorMessage(error: Error) {
  if (error instanceof NoEthereumProviderError) {
    return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network.";
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorWalletConnect ||
    error instanceof UserRejectedRequestErrorFrame
  ) {
    return "Please authorize this website to access your Ethereum account.";
  } else {
    console.error(error);
    return "An unknown error occurred. Check the console for more details.";
  }
}

const connectorsByName: any = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
};

function Account() {
  const context = useWeb3React<Web3Provider>();
  const { connector, account, activate, deactivate, active, error } = context;
  const [activatingConnector, setActivatingConnector] = useState<any>();
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();
  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  let accountOutput = !!account ? account.substr(0, 6) + "..." + account?.substr(-4) : "connect";

  return (
    <Row align={"center"} justify={"center"}>
      <Col>
        <Button
          style={{
            borderRadius: "1rem",
            borderColor: active ? "green" : "orange",
          }}
          outline
          color={"success"}
          key="loginbutton"
          // style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4, borderRadius: "5%" }}
          shape="round"
          size="large"
          onClick={toggle}
        >
          {accountOutput ? accountOutput : "connect"}
        </Button>
      </Col>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Wallet Picker</ModalHeader>
        <ModalBody>
          <div
            style={{
              display: "grid",
              gridGap: "1rem",
              gridTemplateColumns: "1fr 1fr",
              maxWidth: "20rem",
              margin: "auto",
            }}
          >
            {Object.keys(connectorsByName).map(name => {
              const currentConnector = connectorsByName[name];
              const activating = currentConnector === activatingConnector;
              const connected = currentConnector === connector;
              const disabled = !triedEager || !!activatingConnector || connected || !!error;

              return (
                <Button
                  outline
                  style={{
                    borderRadius: "1rem",
                    borderColor: activating ? "orange" : connected ? "green" : "unset",
                    cursor: disabled ? "unset" : "pointer",
                    position: "relative",
                  }}
                  disabled={disabled}
                  key={name}
                  onClick={async () => {
                    setActivatingConnector(currentConnector);
                    await activate(connectorsByName[name]);
                    toggle();
                  }}
                >
                  {name}
                </Button>
              );
            })}
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {!!error && <h4 style={{ marginTop: "1rem", marginBottom: "0" }}>{getErrorMessage(error)}</h4>}
          </div>

          <ModalFooter>
            {connector === connectorsByName[ConnectorNames.WalletConnect] && (
              <Button
                color="danger"
                onClick={() => {
                  (connector as any).close();
                }}
              >
                Kill WalletConnect Session
              </Button>
            )}

            {(active || error) && (
              <Button
                outline
                color="warning"
                onClick={() => {
                  deactivate();
                }}
              >
                Deactivate
              </Button>
            )}
          </ModalFooter>
        </ModalBody>
      </Modal>
    </Row>
  );
}

export default Account;
