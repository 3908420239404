export const INFURA_ID = "1f655e95a05f4029a130a6605f6840f2";

export const BLOCK_NATIVE_KEY = "6e6166ba-8d62-4fee-b762-fb049576ed55";

export const ETHERSCAN_KEY = "PSW8C433Q667DVEX5BCRMGNAH9FSGFZ7Q8";

const zBASVault = {
  contractName: "zBASVault",
  asset: "BAS",
  assetToken: "0xa7ED29B253D8B4E3109ce07c80fc570f81B63696",
  strategyName: "BasisCashRewardStrategy",
  address: "0xf22a1F2666A92a5c7285df6b3a1c52086f0F3EE9",
  vaultAddress: "0xf22a1F2666A92a5c7285df6b3a1c52086f0F3EE9",
  strategyAddress: "0x2c95abDB21615E79A22598fAD30b226a41e2479C",
  rewardAddress: "0x4b182469337d46e6603ed7e26ba60c56930a342c",
};

const zBACDAILPVault = {
  contractName: "zBACDAILPVault",
  asset: "BACDAILP",
  assetToken: "0xd4405F0704621DBe9d4dEA60E128E0C3b26bddbD",
  strategyName: "BACDAILPRewardStrategy",
  vaultAddress: "0xED7f8801568Ed1759F0C49D589Bc7f41f9714b1f",
  address: "0xED7f8801568Ed1759F0C49D589Bc7f41f9714b1f",
  strategyAddress: "0x17f8e306e6219D326334c77d04423340e247581c",
  rewardAddress: "0x067d4D3CE63450E74F880F86b5b52ea3edF9Db0f",
};

export const SYSTEM_CONTRACTS = {
  BAS: ["Token", "0xa7ED29B253D8B4E3109ce07c80fc570f81B63696"],
  BAC: ["Token", "0x3449FC1Cd036255BA1EB19d65fF4BA2b8903A69a"],
  DAI: ["Token", "0x6B175474E89094C44Da98b954EedeAC495271d0F"],
  BACDAILP: ["Token", "0xd4405F0704621DBe9d4dEA60E128E0C3b26bddbD"],
  BASReward: ["Token", "0x4b182469337d46e6603ed7e26ba60c56930a342c"],
  BACDAIReward: ["Token", "0x067d4D3CE63450E74F880F86b5b52ea3edF9Db0f"],
  zBASVault: ["Vault", zBASVault],
  zBACDAILPVault: ["Vault", zBACDAILPVault],
};
